import { styled } from '@mui/system'

const List = styled('div')`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 500px;
	margin: auto;
	gap: 13px;
	${({ theme }) => theme.breakpoints.up('md')} {
		max-width: 915px;
		gap: 39px;
	}
`
const ListItem = styled('div')`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	width: 50px;
	height: 40px;
	border: 1px solid ${({ theme }) => theme.palette.primary.main};
	display: flex;
	${({ theme }) => theme.breakpoints.up('md')} {
		width: 80px;
		height: 64px;
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
	.MuiLink-root {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${({ theme }) => theme.palette.white.main};
		transition: all 0.2s ease-out;
		color: ${({ theme }) => theme.palette.primary.main};
		:hover {
			background-color: ${({ theme }) => theme.palette.primary.main};
			color: ${({ theme }) => theme.palette.white.main};
		}
	}
`

export { List, ListItem }
