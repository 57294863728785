import { useProductsLayeredAttributes } from '@bluheadless/ui-logic/src/hooks/product/useProductsLayeredAttributes'
import { useCategory } from '@bluheadless/ui-logic/src/hooks/category/useCategory'
import { SkeletonCard } from '@bluheadless/ui/src/molecules/skeleton/card/skeleton-card'
import Link from '@bluheadless/ui/src/molecules/link'
import { Box } from '@mui/material'
import { cx } from '@emotion/css'
import { List, ListItem } from './category-size-list.styled'

const CategorySizeList = ({ sx, cssProp, className, productsCorrelation }) => {
	const categoryId = productsCorrelation?.categoryId

	const { path: categoryPath, loading: loadingCategory } = useCategory({
		id: categoryId,
	})

	const { attributes, loading: loadingLayered } = useProductsLayeredAttributes({
		paused: loadingCategory,
		where: { categories: categoryId },
	})

	const sizesList = attributes?.find((attribute) => attribute.id == 'size')?.values

	return (
		<Box
			sx={{ ...sx, width: '100%' }}
			css={cssProp}
			className={cx('Magento-PageBuilder-Category-Size-List', className)}
		>
			<List>
				{loadingLayered && sizesList?.length === 0 && <SkeletonCard />}
				{sizesList?.length > 0 &&
					sizesList?.map((size) => {
						return (
							<ListItem key={size.label}>
								<Link
									p={2}
									href={{
										pathname: categoryPath,
										query: { size: size.label },
									}}
									underline="none"
								>
									{size.label}
								</Link>
							</ListItem>
						)
					})}
			</List>
		</Box>
	)
}

export default CategorySizeList
